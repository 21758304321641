import React from "react";

function SupportSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="61.469"
      height="61.426"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1="-1.375"
          x2="1.987"
          y1="1.642"
          y2="-0.428"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="red"></stop>
          <stop offset="1" stopColor="#d4145a"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="-1.008"
          x2="1.661"
          y1="1.85"
          y2="-0.373"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="c"
          x1="-3.488"
          x2="6.424"
          y1="2.536"
          y2="-2.266"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="d"
          x1="-6.099"
          x2="3.802"
          y1="3.645"
          y2="-1.156"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="e"
          x1="-3.686"
          x2="4.629"
          y1="7.006"
          y2="-5.919"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="f"
          x1="-1.534"
          x2="2.011"
          y1="6.431"
          y2="-3.422"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="g"
          x1="-2.328"
          x2="4.989"
          y1="3.167"
          y2="-4.004"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="h"
          x1="-0.092"
          x2="1.467"
          y1="1.962"
          y2="-1.19"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="i"
          x1="-2.96"
          x2="4.356"
          y1="4.072"
          y2="-3.099"
          xlinkHref="#a"
        ></linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M1101.917 526.233a12.735 12.735 0 01-12.414-9.838c-.552-2.347-1.4-6.825-1.662-9.07a18.372 18.372 0 01.1-5.577l.024-.21c.792-5.918 6.794-10.391 13.956-10.391s13.158 4.466 13.963 10.391l.03.228a18.417 18.417 0 01.09 5.559c-.264 2.275-1.11 6.747-1.675 9.07a12.733 12.733 0 01-12.412 9.838zm0-33.165c-6.2 0-11.381 3.751-12.053 8.729l-.03.21a16.731 16.731 0 00-.084 5.1c.24 2.149 1.092 6.6 1.627 8.848a10.828 10.828 0 0021.087 0c.535-2.227 1.381-6.669 1.621-8.848a16.5 16.5 0 00-.072-5.085l-.036-.228c-.677-4.975-5.852-8.726-12.06-8.726z"
        transform="translate(-1070.979 -487.402)"
      ></path>
      <path
        fill="url(#b)"
        d="M1115.532 519a.9.9 0 01-.217-.03.979.979 0 01-.72-1.165c.69-2.827 1.777-8.512 2.1-11.322a20.892 20.892 0 00-.114-6.549l-.042-.294c-.876-6.489-7.6-11.387-15.667-11.387s-14.791 4.9-15.661 11.387l-.036.282a21.323 21.323 0 00-.12 6.56c.306 2.624 1.248 7.63 1.921 10.589l8.722-.024h.012a.96.96 0 01.006 1.921l-9.49.03h-.006a.988.988 0 01-.942-.732c-.685-2.851-1.789-8.644-2.125-11.562a22.8 22.8 0 01.12-7.041l.036-.282c1-7.437 8.548-13.044 17.564-13.044s16.574 5.606 17.565 13.044l.036.288a22.251 22.251 0 01.119 7.041c-.318 2.864-1.434 8.668-2.118 11.549a.962.962 0 01-.943.741z"
        transform="translate(-1069.934 -486.343)"
      ></path>
      <path
        fill="url(#c)"
        d="M1082.945 523.993a7.6 7.6 0 01-7.467-6.344 7.615 7.615 0 016.231-8.764.974.974 0 011.1.792.954.954 0 01-.786 1.1 5.693 5.693 0 00-2.347 10.247 5.614 5.614 0 004.232.961.962.962 0 11.324 1.9 7.881 7.881 0 01-1.287.108z"
        transform="translate(-1068.267 -491.308)"
      ></path>
      <path
        fill="url(#d)"
        d="M1126.219 523.992a7.889 7.889 0 01-1.29-.108.956.956 0 01-.787-1.111.977.977 0 011.11-.786 5.682 5.682 0 101.88-11.208.951.951 0 01-.781-1.1.975.975 0 011.1-.792 7.607 7.607 0 01-1.236 15.109z"
        transform="translate(-1079.013 -491.308)"
      ></path>
      <path
        fill="url(#e)"
        d="M1107.276 528.981h-5.906a2.809 2.809 0 110-5.618h5.906a2.809 2.809 0 010 5.618zm-5.906-3.7a.891.891 0 100 1.782h5.906a.891.891 0 000-1.782z"
        transform="translate(-1073.378 -494.502)"
      ></path>
      <path
        fill="url(#f)"
        d="M1114.678 509.006a5.7 5.7 0 01-5.8-4.838c-2.311 1.357-6.717 3.475-19.3 3.475a.96.96 0 110-1.921c13.019 0 16.772-2.275 18.806-3.5.306-.18.571-.342.828-.48a.975.975 0 01.943.03.953.953 0 01.456.822c.012.456.138 4.49 4.064 4.49a.96.96 0 010 1.921z"
        transform="translate(-1071.187 -489.713)"
      ></path>
      <path
        fill="url(#g)"
        d="M1091.965 548.9a.939.939 0 01-.894-.613l-3.223-8.2a.968.968 0 01.552-1.249.955.955 0 011.243.547l2.9 7.413 7.119-1.909a.985.985 0 011.183.678.961.961 0 01-.678 1.176l-7.954 2.125a1.156 1.156 0 01-.248.032z"
        transform="translate(-1071.003 -497.897)"
      ></path>
      <path
        fill="url(#h)"
        d="M1067.226 558.62a.967.967 0 01-.96-.918c-.018-.4-.408-10.108 8.092-15.427l11.976-6.573a.95.95 0 011.152.18c2.281 2.395 5.547 3.607 9.712 3.607s7.431-1.212 9.719-3.607a.956.956 0 011.159-.18l11.924 6.544c8.572 5.36 7.726 15.1 7.684 15.5a.959.959 0 11-1.909-.186c.03-.366.756-8.968-6.759-13.668l-11.243-6.165c-2.617 2.443-6.177 3.68-10.577 3.68s-7.954-1.236-10.576-3.68l-11.3 6.195c-7.5 4.7-7.144 13.59-7.137 13.681a.968.968 0 01-.912 1.008.146.146 0 01-.045.009z"
        transform="translate(-1066.259 -497.195)"
      ></path>
      <path
        fill="url(#i)"
        d="M1116.248 548.9a1.126 1.126 0 01-.253-.03l-7.965-2.125a.96.96 0 01.5-1.855l7.131 1.909 2.9-7.413a.96.96 0 011.789.7l-3.212 8.205a.947.947 0 01-.89.609z"
        transform="translate(-1075.308 -497.897)"
      ></path>
    </svg>
  );
}

export default SupportSvg;