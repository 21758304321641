import React from 'react'
import { useTranslation } from 'react-i18next';
import DescriptionComponent from '../../components/DescriptionComponent';
import HtmlComponent from '../../components/HtmlComponent';

const CallPage = (props) => {
    const {t} = useTranslation();
    return (
        <DescriptionComponent title={`${t('help.service_title')} – ${t('help.call.sec_title')}`} custom>
            <HtmlComponent text={t('help.call.content')} />
        </DescriptionComponent>
    )
}


export default CallPage;