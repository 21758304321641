import React, { useCallback } from 'react'
import { Box, Paper, Typography, Grid, makeStyles } from '@material-ui/core';
import ButtonComponent from '../../../components/ButtonComponent';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: 'bold',
    },
    content: {
        [theme.breakpoints.down('xs')]: {
            wordBreak: 'break-word'
        },
        [theme.breakpoints.up('md')]: {
            wordBreak: 'initial'
        },
    },
    paper: {
        height: '100%'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px',
        height: '100%'
    },
    title: {
        marginTop: '20px'
    },
    link: {
        textDecoration: 'none',
    }
}))

const ServiceComponent = ({ Icon, title, content, url, href }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const handleRoute = useCallback(() => {
        ReactGA.event({
            category: 'Button',
            action: `Przeszdł na strone ${title}`
        });
        if (url) history.push(url || href);
        else {
            const win = window.open(href, '_blank');
            win.focus();
        };
    }, [url, href, history, title])
    return (
        <Grid item lg={4} sm={6} xs={12} data-aos='fade-up'>
            <Paper className={classes.paper}>
                <Box className={classes.container}>
                    <Box mb='20px'>
                        <Icon />
                        <Typography variant='h6' component='h2' color='primary' className={classes.title}>
                            {t(`${title}.service_title`)}
                        </Typography>
                        <Box mt='20px' mb='20px'>
                            {
                                Array.isArray(content)
                                    ? content.map((e, i) =>
                                        <Typography
                                            key={i}
                                            className={classes.boldText}
                                            variant='body1'>
                                            {e}
                                        </Typography>
                                    )
                                    : <Typography variant='body1' className={classes.content} dangerouslySetInnerHTML={{ __html: t(`${title}.service_content`) }} />
                            }
                        </Box>
                    </Box>
                    <Box>
                        {url ? <Link to={url} title={t(`${title}.service_title`)} className={classes.link}>
                            <ButtonComponent onClick={handleRoute}>
                                {t(`buttons.more`)}
                            </ButtonComponent>
                        </Link>
                            : <ButtonComponent onClick={handleRoute}>
                                {t(`buttons.more`)}
                            </ButtonComponent>
                        }
                    </Box>
                </Box>
            </Paper>
        </Grid>
    )
}


export default ServiceComponent;