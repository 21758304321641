export const MAIN_ROUTE = "/";
export const ENTREPRENEURS_ROUTE = "/dla_przedsiebiorcow";
export const SLS_ROUTE = "/sls";
export const KEEP_UP_ROUTE = "/badz_na_biezaco";
export const ARTISTS_ROUTE = "/dla_artystow";
export const SZCZECIN_SCHOOL_ROUTE = "/gotowi_do_szkoly";
export const ADMINISTRATION_ROUTE = "/zalatw_sprawe_w_urzedzie";
export const INFO_ROUTE = "/obostrzenia";
export const VACCINATION_ROUTE = "/szczepienia";
export const HELP_ROUTE = "/szczecin_pomaga";
export const NEIGHBOUR_ROUTE = "/pomoc_sasiedzka_jak_sie_masz";
export const VOLUNTARY_ROUTE = "/wolontariat";
export const CALL_ROUTE = "/seniorzy";
