import React, { useCallback } from 'react'
import { Box, Typography } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
    cookie: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px 30px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: '0',
        visibility: 'hidden',
        opacity: '0',
        left: '0',
        background: 'white',
        zIndex: '200',
        boxShadow: '1px 3px 3px 3px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    },
    link: {
        wordBreak: 'break-all',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    activeCookie: {
        animation: `$myEffect 1s ease-in 2s forwards`
    },
    "@keyframes myEffect": {
        "0%": {
            visibility: 'hidden',
            opacity: '0',
        },
        "100%": {
            visibility: 'visible',
            opacity: '1',
        }
    },

}))
const CookiesComponent = (props) => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies(['cookies-shown']);
    const { t } = useTranslation();
    const handleAccept = useCallback(() => {
        setCookie('cookies-shown', true, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        });
    }, [setCookie])
    return (
        <Box className={`${classes.cookie} ${!cookies['cookies-shown'] ? classes.activeCookie : ''}`} >
            <Box mb='30px' mt='30px'>
                <Typography variant='body1' color='primary'>
                    Od 25 maja 2018 roku obowiązuje Rozporządzenie Parlamentu Europejskiego i 
                    Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. (zwane dalej RODO). Zgodnie z 
                    RODO potrzebujemy Twojej zgody na przetwarzanie Twoich danych osobowych 
                    przechowywanych w plikach cookies. Poniżej znajdziesz szczegóły na ten temat: 
                    Zgadzam się na przechowywanie na urządzeniu, z którego korzystam tzw. plików 
                    cookies oraz na przetwarzanie moich danych osobowych pozostawianych w czasie 
                    korzystania przeze mnie ze stron internetowej lub serwisów oraz innych parametrów 
                    zapisywanych w plikach cookies w celach marketingowych i w celach analitycznych 
                    przez Szczecińską Agencję Artystyczną. Przeczytaj naszą <a href='https://www.szczecin.eu/polityka_prywatnosci' className={classes.link} target="_blank" rel="noopener noreferrer" title='Polityka prywatności'>politykę prywatności</a>.
            </Typography>
            </Box>
            <ButtonComponent onClick={handleAccept}>
                {t('buttons.undestand')}
            </ButtonComponent>
        </Box>
    )
}


export default CookiesComponent;