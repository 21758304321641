import React from "react";
import { Typography, Box } from "@material-ui/core";
import TextComponent from "../../components/TextComponent";
import DescriptionComponent from "../../components/DescriptionComponent";
import { useTranslation } from "react-i18next";

const EnterpreneursPage = (props) => {
  const { t } = useTranslation();
  return (
    <DescriptionComponent title="enterpreneurs">
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{ __html: t("enterpreneurs.introduction") }}
      />
      <TextComponent title={t("enterpreneurs.property_tax_title")} />
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{
          __html: t("enterpreneurs.property_tax_content"),
        }}
      />
      <TextComponent title={t("enterpreneurs.rent_title")} />
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{ __html: t("enterpreneurs.rent_content") }}
      />
      <TextComponent title={t("enterpreneurs.revolving_title")} />
      <Typography
        variant="subtitle1"
        component={"span"}
        dangerouslySetInnerHTML={{
          __html: t("enterpreneurs.revolving_content"),
        }}
      />
      <TextComponent title={t("enterpreneurs.other_amenities")} />
      <Box mb="40px">
        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{
            __html: t("enterpreneurs.other_amenities_content_1"),
          }}
        />
      </Box>
    </DescriptionComponent>
  );
};

export default EnterpreneursPage;
