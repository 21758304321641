import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core';
import ContainerComponent from '../../components/ContainerComponent';
import EmptySvg from '../../svg/EmptySvg';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../components/ButtonComponent';
import { Link } from 'react-router-dom';
import { MAIN_ROUTE } from '../../routes';

const useStyles = makeStyles((theme) => ({
	emptyIcon: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	text: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	link: {
		textDecoration: 'none',
		
	}
}))

const EmptyPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<ContainerComponent>
			<Container className={classes.container}>
				<EmptySvg className={classes.emptyIcon} />
				<Typography className={classes.text}>{t('empty_page.title')}</Typography>
				<Link to={MAIN_ROUTE} title={t('main.title')} className={classes.link}>
					<ButtonComponent >{t('buttons.back_main_page')}</ButtonComponent>
				</Link>
			</Container>
		</ContainerComponent>
	)
}


export default React.memo(EmptyPage);