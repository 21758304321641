import React from "react";

function ClockSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="63.631"
            height="63.63"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="-1.659"
                    x2="1.86"
                    y1="1.402"
                    y2="-0.148"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="red"></stop>
                    <stop offset="1" stopColor="#d4145a"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="0.009"
                    x2="0.974"
                    y1="0.859"
                    y2="0.133"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="c"
                    x1="-1.966"
                    x2="3.346"
                    y1="1.725"
                    y2="-0.786"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="d"
                    x1="-2.297"
                    x2="2.336"
                    y1="2.158"
                    y2="-0.397"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="e"
                    x1="-10.784"
                    x2="4.658"
                    y1="8.998"
                    y2="-2.631"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="f"
                    x1="-3.763"
                    x2="11.675"
                    y1="3.711"
                    y2="-7.918"
                    xlinkHref="#a"
                ></linearGradient>
            </defs>
            <path
                fill="url(#a)"
                d="M1190.881 441.241a1 1 0 00-.994.994v16.992a5.973 5.973 0 105.835 2.4l6.351-6.636a1 1 0 00-1.411-1.4l-6.348 6.645a5.921 5.921 0 00-2.439-1.005v-16.996a1 1 0 00-.994-.994zm0 27.839a3.977 3.977 0 113.977-3.977 3.989 3.989 0 01-3.977 3.977z"
                transform="translate(-1159.065 -433.287)"
            ></path>
            <path
                fill="url(#b)"
                d="M1186.7 432a31.815 31.815 0 1012.1 61.227h-7.351A29.909 29.909 0 111216 469.308a2.942 2.942 0 011.728 1.449A31.727 31.727 0 001186.7 432z"
                transform="translate(-1154.882 -432)"
            ></path>
            <path
                fill="url(#c)"
                d="M1200.219 494.6l1.423-1.33c3.815-3.679 6.238-6.381 6.238-9.718 0-2.6-1.64-5.269-5.542-5.269a8.02 8.02 0 00-5.1 1.839l.783 1.727a6.178 6.178 0 013.846-1.528 3.21 3.21 0 013.567 3.535c0 2.729-2.125 5.064-6.748 9.464l-1.914 1.87v1.5h11.557v-2.032h-8.109z"
                transform="translate(-1160.717 -438.446)"
            ></path>
            <path
                fill="url(#d)"
                d="M1223.105 478.638h-2.641l-8.078 11.558v1.585h8.408v4.959h2.312v-4.959h2.535v-1.9h-2.535zm-2.312 5.207v6.04h-5.928v-.056l4.425-6.151c.509-.839.944-1.616 1.5-2.679h.087a51.018 51.018 0 00-.084 2.846z"
                transform="translate(-1162.892 -438.496)"
            ></path>
            <path
                fill="url(#e)"
                d="M1218.926 468.642a1.988 1.988 0 10-1.988 1.988 1.99 1.99 0 001.988-1.988z"
                transform="translate(-1163.249 -436.827)"
            ></path>
            <path
                fill="url(#f)"
                d="M1164.123 468.642a1.989 1.989 0 101.988-1.988 1.99 1.99 0 00-1.988 1.988z"
                transform="translate(-1156.169 -436.827)"
            ></path>
        </svg>
    );
}

export default ClockSvg;
