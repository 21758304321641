import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import {classNames} from  '../functions/classNames';

const useStyles = makeStyles((theme) => ({
    link: {
        wordBreak: 'break-all',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        paddingBottom: '.25em',
        '&:hover': {
            backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23453886' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E")`,
            backgroundPosition: '0 100%',
            backgroundSize: 'auto 5px',
            backgroundRepeat: 'repeat-x',
            textDecoration: 'none',
        }
    },
}))

const LinkComponent = ({ url, title, tel, className = '' }) => {
    const classes = useStyles();
    return (
        <>
            {title
                ? <a href={`${tel ? 'tel:+48' : ''}${url}`} title={title} target="_blank" rel="noopener noreferrer" className={classNames([classes.link, className])} >
                    {title}
                </a >
                : <Typography variant='body1' className={className}>
                    <a href={`${tel ? 'tel:+48' : ''}${url}`} title={title} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        {url}
                    </a>
                </Typography>
            }
        </>
    )
}


export default LinkComponent;