import React from 'react'
import DescriptionComponent from '../../components/DescriptionComponent';
import { Typography, Box, makeStyles } from '@material-ui/core';
import TextComponent from '../../components/TextComponent';
import LinkComponent from '../../components/LinkComponent';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    link: {
        marginBottom: 20,
    }
}))
const ArtistsPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <DescriptionComponent title='artists'>
            <TextComponent title={t('artists.scholarships_title')}>
                {t('artists.scholarships_content')}
            </TextComponent>
            <Box mt='40px' mb='40px'>
                <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('artists.scholarships_items') }} />
            </Box>
            <Typography variant='body1'>
                {t('artists.details_title')}
            </Typography>
            <LinkComponent
                url='http://bip.um.szczecin.pl/chapter_11071.asp?soid=8F95273DD2364CBBB04E1761BAF51C04'
            />

            <TextComponent title={t('artists.grants_program_title')} >
                {t('artists.grants_program_content')}
            </TextComponent>
            <Box mt='40px' mb='40px'>
                <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('artists.grants_program_items') }}>
                </Typography>
            </Box>
            <Typography variant='body1' dangerouslySetInnerHTML={{ __html:  t('artists.more_title') }}>
                </Typography>
            <LinkComponent
                url='https://trafo.art/'
                className={classes.link}
            />
              
        </DescriptionComponent>
    )
}


export default ArtistsPage;