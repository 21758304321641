import { Box, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import DescriptionComponent from '../../components/DescriptionComponent';
import HtmlComponent from '../../components/HtmlComponent';

const useStyles = makeStyles((theme) => ({
    btnBox: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
         flexDirection: 'column',
        },
    },
    link: {
        textDecoration: 'none',
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginBottom: 20,
        },
    }
}))

const VoluntaryPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const handleBack = useCallback(() => {
        history.goBack();
    }, [history])
    return (
        <DescriptionComponent title={`${t('help.service_title')} – ${t('help.voluntary.title')}`} custom hiddenBtn>
            <HtmlComponent text={t('help.voluntary.content')} />
            <Box className={classes.btnBox}>
                <a href='https://forms.gle/za9JKGkyaaZGjCoB7' className={classes.link} title={`${t('help.service_title')} – ${t('help.voluntary.title')}`}>
                    <ButtonComponent >
                        {t('buttons.form')}
                    </ButtonComponent>
                </a>
                <ButtonComponent onClick={handleBack}>
                    {t('buttons.back')}
                </ButtonComponent>
            </Box>
        </DescriptionComponent>
    )
}


export default VoluntaryPage;