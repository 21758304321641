import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pl from './pl.json'
import de from './de.json'
import en from './en.json'
import uk from './uk.json'
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    pl: {
        translation: pl
    },
    en: {
        translation: en
    },
    uk: {
        translation: uk
    },
    de: {
        translation: de
    },
};

i18n
    .use(initReactI18next) 
    .init({
        resources,
        lng: localStorage.getItem('lang') ||  "pl",
        fallbackLng: ['pl', 'en', 'uk', 'de'],

        // keySeparator: false, 

        interpolation: {
            escapeValue: false 
        }
    });

export default i18n;