import React from 'react'
import { useTranslation } from 'react-i18next';
import DescriptionComponent from '../../components/DescriptionComponent';
import HtmlComponent from '../../components/HtmlComponent';

const NeighbourPage = (props) => {
    const {t} = useTranslation();
    return (
        <DescriptionComponent title={`${t('help.service_title')} – ${t('help.neighbour.title')}`} custom>
            <HtmlComponent text={t('help.neighbour.content')} />
        </DescriptionComponent>
    )
}


export default NeighbourPage;