import React from "react";

function HandsSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="63.662"
            height="60.716"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="-1.677"
                    x2="1.817"
                    y1="2.525"
                    y2="-0.8"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="red"></stop>
                    <stop offset="1" stopColor="#d4145a"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="-5.727"
                    x2="2.533"
                    y1="3.379"
                    y2="-0.321"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="c"
                    x1="-9.36"
                    x2="4.192"
                    y1="7.034"
                    y2="-1.988"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="d"
                    x1="-2.635"
                    x2="5.146"
                    y1="2.733"
                    y2="-2.814"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="e"
                    x1="-2.381"
                    x2="4.309"
                    y1="2.582"
                    y2="-2.224"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="f"
                    x1="-2.369"
                    x2="4.018"
                    y1="2.63"
                    y2="-2.017"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="g"
                    x1="-3.419"
                    x2="5.071"
                    y1="3.609"
                    y2="-3.196"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="h"
                    x1="-6.314"
                    x2="1.98"
                    y1="3.795"
                    y2="-0.031"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="i"
                    x1="-27.383"
                    x2="7.559"
                    y1="10.102"
                    y2="-1.925"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="j"
                    x1="-3.162"
                    x2="3.32"
                    y1="13.829"
                    y2="-9.947"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="k"
                    x1="-5.121"
                    x2="10.223"
                    y1="4.279"
                    y2="-6.035"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="l"
                    x1="-3.226"
                    x2="2.638"
                    y1="2.854"
                    y2="-0.832"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="m"
                    x1="-3.6"
                    x2="3.547"
                    y1="4.992"
                    y2="-2.986"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="n"
                    x1="-7.158"
                    x2="7.538"
                    y1="10.526"
                    y2="-8.905"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="o"
                    x1="-3.518"
                    x2="4.481"
                    y1="1.618"
                    y2="-0.87"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="p"
                    x1="-8.75"
                    x2="11.96"
                    y1="4.257"
                    y2="-4.354"
                    xlinkHref="#a"
                ></linearGradient>
            </defs>
            <path
                fill="url(#a)"
                d="M1282.186 508.242a4.829 4.829 0 01-3.445-1.212 5.547 5.547 0 01-1.772-3.674 1.144 1.144 0 01.081-.4l4.458-10.4a1.113 1.113 0 01.367-.448c.8-.522 7.883-5.122 10.842-5.4 3.482-.323 13.541.957 13.957 1.019a.993.993 0 11-.242 1.971c-.106-.018-10.277-1.312-13.528-1.008-2.014.186-7.448 3.476-9.686 4.918l-4.24 9.9a3.99 3.99 0 001.169 2.107c1.063 1.057 3.792.591 4.625-.068 1.281-1.032 4.757-6.024 5.987-7.846a.98.98 0 011.1-.4c3.233.927 9.1 1.977 11.116.846a1 1 0 011.356.385.99.99 0 01-.386 1.35c-2.953 1.654-9.642.118-11.936-.478-1.194 1.746-4.495 6.477-5.993 7.69a6.486 6.486 0 01-3.83 1.148z"
                transform="translate(-1258.761 -473.736)"
            ></path>
            <path
                fill="url(#b)"
                d="M1326.024 502.915a1 1 0 01-.97-.8c-.025-.093-2.014-9.481-10.37-13.2a1.007 1.007 0 01-.6-1.008.992.992 0 01.759-.87c1.617-.4 6.124-1.754 7.585-3.214a.993.993 0 011.411 0 .979.979 0 010 1.4c-1.336 1.337-4.085 2.406-6.118 3.06 7.423 4.619 9.195 13.055 9.276 13.453a.983.983 0 01-.777 1.162 1.105 1.105 0 01-.196.017z"
                transform="translate(-1267.287 -473.015)"
            ></path>
            <path
                fill="url(#c)"
                d="M1326.707 521.815a.973.973 0 01-.64-.236.989.989 0 01-.112-1.4 13.49 13.49 0 002.363-3.842.958.958 0 01.5-.609l3.32-1.747a1 1 0 011.349.411.982.982 0 01-.422 1.343l-2.972 1.56a16.975 16.975 0 01-2.624 4.184 1 1 0 01-.762.336z"
                transform="translate(-1269.959 -479.985)"
            ></path>
            <path
                fill="url(#d)"
                d="M1265.5 529.974a3.979 3.979 0 01-3.009-1.386l-.5-.609a3.953 3.953 0 01.478-5.533l5.384-4.526a4.031 4.031 0 015.521.473l.516.615a3.858 3.858 0 01.509.783 3.93 3.93 0 01-.981 4.75l-5.39 4.513a3.982 3.982 0 01-2.528.92zm4.88-10.986a1.952 1.952 0 00-1.25.461l-5.377 4.513a1.974 1.974 0 00-.224 2.754l.485.591a2 2 0 002.736.218l5.39-4.514a1.943 1.943 0 00.485-2.338 2.042 2.042 0 00-.255-.385l-.516-.6a1.912 1.912 0 00-1.474-.7z"
                transform="translate(-1255.112 -480.715)"
            ></path>
            <path
                fill="url(#e)"
                d="M1272.673 534.819c-.118 0-.237-.006-.354-.012a3.965 3.965 0 01-2.7-1.4l-.523-.622a3.959 3.959 0 01.511-5.6.993.993 0 011.268 1.53 1.977 1.977 0 00-.255 2.792l.522.622a2.009 2.009 0 001.349.7 1.964 1.964 0 001.437-.454l7.516-6.366a2.023 2.023 0 00.243-2.822l-.5-.585a2 2 0 00-1.8-.7.994.994 0 11-.267-1.97 3.96 3.96 0 013.581 1.38l.51.585a4.013 4.013 0 01-.473 5.632l-7.51 6.36a3.927 3.927 0 01-2.555.93z"
                transform="translate(-1256.739 -481.37)"
            ></path>
            <path
                fill="url(#f)"
                d="M1279.937 540.284a2.048 2.048 0 01-.348-.019 3.94 3.94 0 01-2.735-1.41l-.535-.635a3.968 3.968 0 01-.927-2.916 4.011 4.011 0 011.418-2.741.995.995 0 111.28 1.523 2.028 2.028 0 00-.248 2.854l.534.64a2.008 2.008 0 001.38.715 2.061 2.061 0 001.474-.466l8.163-7.05a2.041 2.041 0 00.255-2.872l-.528-.634a2.171 2.171 0 00-.7-.528.992.992 0 11.814-1.809 4.1 4.1 0 011.4 1.051l.535.646a4.038 4.038 0 01-.485 5.658l-8.163 7.037a3.966 3.966 0 01-2.584.956z"
                transform="translate(-1258.396 -482.507)"
            ></path>
            <path
                fill="url(#g)"
                d="M1288.771 545.561a3.082 3.082 0 01-.342-.018 3.842 3.842 0 01-2.637-1.374l-.491-.572a3.839 3.839 0 01.491-5.458 1 1 0 011.4.124 1.008 1.008 0 01-.131 1.405 1.864 1.864 0 00-.672 1.275 1.844 1.844 0 00.429 1.361l.491.572a1.905 1.905 0 001.287.69 1.853 1.853 0 001.367-.441l4.34-3.811a1.9 1.9 0 00.249-2.661.99.99 0 111.511-1.281 3.864 3.864 0 01-.46 5.44l-4.34 3.83a3.838 3.838 0 01-2.492.919z"
                transform="translate(-1260.465 -484.845)"
            ></path>
            <path
                fill="url(#h)"
                d="M1331.048 488.634a.993.993 0 01-.84-.454 44.105 44.105 0 00-10.538-10.762.994.994 0 01.062-1.66c.33-.2 8.039-4.805 11.085-5.825a1 1 0 11.634 1.89 75.977 75.977 0 00-9.4 4.886 44.975 44.975 0 019.835 10.407 1 1 0 01-.311 1.374 1.024 1.024 0 01-.527.144z"
                transform="translate(-1268.474 -469.882)"
            ></path>
            <path
                fill="url(#i)"
                d="M1318.934 483.3a.977.977 0 01-.939-.684c-.553-1.647.783-4.258 1.063-4.756a.99.99 0 111.741.945c-.584 1.094-1.106 2.636-.92 3.189a1 1 0 01-.634 1.256.916.916 0 01-.311.05z"
                transform="translate(-1268.155 -471.593)"
            ></path>
            <path
                fill="url(#j)"
                d="M1270.444 489.719a1.864 1.864 0 01-.267-.032l-2.487-.7a.994.994 0 01.535-1.914l2.244.628a47.1 47.1 0 0112.055-.914.994.994 0 01-.137 1.983 45.334 45.334 0 00-11.718.927.924.924 0 01-.225.022z"
                transform="translate(-1256.462 -473.745)"
            ></path>
            <path
                fill="url(#k)"
                d="M1259.371 520.82a1.051 1.051 0 01-.484-.118 13.628 13.628 0 01-5.384-5.409.994.994 0 111.791-.864 11.728 11.728 0 004.557 4.526 1 1 0 01.391 1.356.988.988 0 01-.871.509z"
                transform="translate(-1253.347 -479.985)"
            ></path>
            <path
                fill="url(#l)"
                d="M1317.307 530.053a4.566 4.566 0 01-3.077-1.182l-10.171-8.3a.995.995 0 011.25-1.548l10.214 8.344a2.629 2.629 0 001.915.7 2.593 2.593 0 001.828-.864 2.638 2.638 0 00-.218-3.711l-11.848-11.176a1 1 0 011.368-1.454l11.818 11.159a4.618 4.618 0 01.348 6.5 4.538 4.538 0 01-3.183 1.523c-.088.009-.168.009-.244.009z"
                transform="translate(-1264.899 -479.233)"
            ></path>
            <path
                fill="url(#m)"
                d="M1312.833 540.882a4.6 4.6 0 01-3.077-1.181l-7.137-6.061a.993.993 0 111.28-1.517l7.162 6.081a2.635 2.635 0 003.729-.18 2.441 2.441 0 00.492-.8 1 1 0 011.866.722 4.671 4.671 0 01-.871 1.417 4.608 4.608 0 01-3.183 1.517c-.094.002-.18.002-.261.002z"
                transform="translate(-1264.572 -484.126)"
            ></path>
            <path
                fill="url(#n)"
                d="M1305.749 545.7a4.736 4.736 0 01-.913-.093 4.559 4.559 0 01-3.251-2.2.994.994 0 011.816-.808 2.815 2.815 0 001.809 1.057 2.039 2.039 0 001.866-.454.993.993 0 111.4 1.411 3.786 3.786 0 01-2.727 1.087z"
                transform="translate(-1264.395 -486.45)"
            ></path>
            <path
                fill="url(#o)"
                d="M1254.344 498.782a.991.991 0 01-.6-1.784c7.647-5.745 11.8-16.021 10.886-18.284-4.993-2.1-10.694-6.807-10.942-7.013a.991.991 0 111.268-1.523c.062.043 5.962 4.905 10.73 6.813a1.042 1.042 0 01.435.336c2.406 3.27-3.164 15.238-11.184 21.262a1.05 1.05 0 01-.593.193z"
                transform="translate(-1253.332 -469.896)"
            ></path>
            <path
                fill="url(#p)"
                d="M1254.325 496.351a1 1 0 010-2 2.176 2.176 0 100-4.352.991.991 0 110-1.983 4.165 4.165 0 010 8.331z"
                transform="translate(-1253.331 -474.048)"
            ></path>
        </svg>
    );
}

export default HandsSvg;
