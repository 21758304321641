import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  MAIN_ROUTE,
  ENTREPRENEURS_ROUTE,
  SLS_ROUTE,
  ARTISTS_ROUTE,
  ADMINISTRATION_ROUTE,
  SZCZECIN_SCHOOL_ROUTE,
  KEEP_UP_ROUTE,
  INFO_ROUTE,
  HELP_ROUTE,
  CALL_ROUTE,
  NEIGHBOUR_ROUTE,
  VOLUNTARY_ROUTE,
  VACCINATION_ROUTE,
} from "./routes";
import MainPage from "./pages/mainPage/MainPage";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import mainTheme from "./MuiTheme";
import EnterpreneursPage from "./pages/enterpreneursPage/EnterpreneursPage";
import ArtistsPage from "./pages/artistsPage/ArtistsPage";
import AdministartionPage from "./pages/administartionPage/AdministartionPage";
import SzczecinSchoolPage from "./pages/szczecinSchoolPage/SzczecinSchoolPage";
import ReactGa from "react-ga";
import AOS from "aos";
import "aos/dist/aos.css";
import { ParallaxProvider } from "react-scroll-parallax";
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import { CookiesProvider } from "react-cookie";
import KeepUpPage from "./pages/keepUpPage/KeepUpPage";
import InfoPage from "./pages/infoPage/InfoPage";
import HelpPage from "./pages/helpPage/HelpPage";
import CallPage from "./pages/callPage/CallPage";
import slsPage from "./pages/slsPage/slsPage";
import VoluntaryPage from "./pages/voluntaryPage/VoluntaryPage";
import NeighbourPage from "./pages/neighbourPage/NeighbourPage";
import VaccinationPage from "./pages/vaccinationsPage/VaccinationPage";
import EmptyPage from "./pages/emptyPage/EmptyPage";

const Root = (props) => {
  useEffect(() => {
    ReactGa.initialize("UA-27642648-12");
    ReactGa.pageview(window.location.pathname + window.location.search);
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <ParallaxProvider>
          <MuiThemeProvider theme={mainTheme}>
            <CssBaseline />
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path={ENTREPRENEURS_ROUTE}
                  component={EnterpreneursPage}
                />
                <Route exact path={ARTISTS_ROUTE} component={ArtistsPage} />
                <Route exact path={SLS_ROUTE} component={slsPage} />
                <Route
                  exact
                  path={ADMINISTRATION_ROUTE}
                  component={AdministartionPage}
                />
                <Route
                  exact
                  path={SZCZECIN_SCHOOL_ROUTE}
                  component={SzczecinSchoolPage}
                />
                <Route exact path={KEEP_UP_ROUTE} component={KeepUpPage} />
                <Route exact path={INFO_ROUTE} component={InfoPage} />
                <Route exact path={HELP_ROUTE} component={HelpPage} />
                <Route exact path={NEIGHBOUR_ROUTE} component={NeighbourPage} />
                <Route exact path={VOLUNTARY_ROUTE} component={VoluntaryPage} />
                <Route exact path={CALL_ROUTE} component={CallPage} />
                <Route exact path={MAIN_ROUTE} component={MainPage} />
                <Route
                  exact
                  path={VACCINATION_ROUTE}
                  component={VaccinationPage}
                />
                <Route component={EmptyPage} />
              </Switch>
            </BrowserRouter>
          </MuiThemeProvider>
        </ParallaxProvider>
      </I18nextProvider>
    </CookiesProvider>
  );
};

export default Root;
