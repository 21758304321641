import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import HtmlComponent from "../../components/HtmlComponent";
import DescriptionComponent from "../../components/DescriptionComponent";

const useStyles = makeStyles((theme) => ({
  intro: {
    marginBottom: 40,
  },
}));
const InfoPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DescriptionComponent title="info">
      <Box className={classes.intro}>
        <HtmlComponent text={t("info.introduction")} />
      </Box>
      <HtmlComponent text={t("info.recoms")} />
    </DescriptionComponent>
  );
};

export default InfoPage;
