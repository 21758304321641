/* eslint-disable react-hooks/rules-of-hooks */
import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DescriptionComponent from "../../components/DescriptionComponent";
import HtmlComponent from "../../components/HtmlComponent";

const useStyles = makeStyles((theme) => ({
  intro: {
    marginBottom: 40,
  },
}));
const VaccinationPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DescriptionComponent title="vaccination">
      <Box className={classes.intro}>
        <HtmlComponent text={t("vaccination.introduction")} />
      </Box>
      <HtmlComponent text={t("vaccination.content")} />
    </DescriptionComponent>
  );
};

export default VaccinationPage;
