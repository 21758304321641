import React from "react";

function TabletSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="68.934"
            height="68.927"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="-0.469"
                    x2="1.065"
                    y1="1.091"
                    y2="0.006"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="red"></stop>
                    <stop offset="1" stopColor="#d4145a"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="-0.028"
                    x2="0.819"
                    y1="0.989"
                    y2="0.194"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="c"
                    x1="-0.212"
                    x2="0.94"
                    y1="0.997"
                    y2="-0.006"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="d"
                    x1="-12.083"
                    x2="1.458"
                    y1="9.36"
                    y2="-0.174"
                    xlinkHref="#a"
                ></linearGradient>
            </defs>
            <g transform="translate(-1349.877 -462)">
                <path
                    fill="url(#a)"
                    d="M1424.82 524.215a17.857 17.857 0 00-2.72-6.416l-2.853-4.242c-1.529-2.134-3.245-3.992-5.858-3.412a20.194 20.194 0 00-1.993.539c-.006.006-.006.006-.013.006l-4.376 1.508c-.02.006-.034.013-.054.02h-.006c-.02 0-.02 0-.047.02-1.037.458-2.612 1.017-3.554 1.326l-6.631-10.1a4.127 4.127 0 00-5.56-1.252 4.044 4.044 0 00-1.259 5.574l9.371 14.272a26.234 26.234 0 00-1.683-.586 4.808 4.808 0 00-1.394-.2 4.006 4.006 0 00-3.971 2.665c-.741 2.188.706 4.5 3.521 5.635l.687.27a54.589 54.589 0 0116.076 9.391 1.075 1.075 0 001.272.088 222.206 222.206 0 006.739-4.416c1.9-1.279 3.77-2.545 6.314-4.141a1.076 1.076 0 00.477-.7 1.108 1.108 0 00-.175-.835 11.881 11.881 0 01-2.31-5.014zm-5.514 8.906c-1.8 1.212-3.608 2.436-6.024 3.958a56.609 56.609 0 00-16.083-9.249l-.653-.249c-1.394-.558-2.7-1.73-2.282-2.955a1.949 1.949 0 011.925-1.2 2.438 2.438 0 01.767.114 36.129 36.129 0 014.706 1.9 1.079 1.079 0 001.373-1.562l-11.323-17.254a1.873 1.873 0 01.586-2.585 1.92 1.92 0 012.606.6l7.1 10.8 2.672 4.059a1.057 1.057 0 00.9.491 1.118 1.118 0 00.586-.175 1.077 1.077 0 00.31-1.495l-1.9-2.894c.7-.249 1.582-.559 2.37-.875l2.154 3.285a1.072 1.072 0 001.488.317 1.084 1.084 0 00.492-.9 1.032 1.032 0 00-.182-.586l-1.878-2.882 2.284-.784 2.026 3.089a1.076 1.076 0 001.8-1.185l-1.676-2.565c.128-.034.269-.06.4-.088 1.259-.282 1.972.223 3.629 2.531l2.828 4.181a16.012 16.012 0 012.423 5.736 12.879 12.879 0 002.006 4.84 247.57 247.57 0 00-5.43 3.582z"
                    transform="translate(-8.513 -8.562)"
                ></path>
                <path
                    fill="url(#b)"
                    d="M1413.426 462h-58.163a5.388 5.388 0 00-5.385 5.385v40.93a5.393 5.393 0 005.385 5.385h25.676a6.846 6.846 0 011.5-2.154h-27.177a3.241 3.241 0 01-3.231-3.231v-40.93a3.236 3.236 0 013.231-3.231h58.163a3.236 3.236 0 013.231 3.231v40.687a28.732 28.732 0 011.488 2.787 5.343 5.343 0 00.666-2.545v-40.93a5.388 5.388 0 00-5.384-5.384z"
                ></path>
                <path
                    fill="url(#c)"
                    d="M1405.661 499.918a7.678 7.678 0 011.649-.189c.021 0 .04.007.06.007V468.87h-50.624v40.93h28.22l-1.414-2.154H1358.9v-36.622h46.315v28.987c.149-.027.291-.067.446-.093z"
                    transform="translate(-1.484 -1.485)"
                ></path>
                <circle
                    cx="2.154"
                    cy="2.154"
                    r="2.154"
                    fill="url(#d)"
                    transform="translate(1409.117 485.696)"
                ></circle>
            </g>
        </svg>
    );
}

export default TabletSvg;
