import React, { useCallback, useState } from "react";
import {
  Container,
  makeStyles,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  // IconButton,
} from "@material-ui/core";
import CoroLogoSvg from "../svg/CoroLogoSvg";
import CoronaLogo from "../images/CoronaLogo.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// INFO_ROUTE
import {  MAIN_ROUTE, VACCINATION_ROUTE } from "../routes";
import ButtonComponent from "./ButtonComponent";
// import iconYellow from "../images/iconYellow.png";
// import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const HeaderComponent = (props) => {
  const { i18n, t } = useTranslation();
  const [show, setShow] = useState();
  const [langs, setLangs] = useState(
    i18n.languages.filter((lang) => lang !== i18n.language)
  );
  const [lang, setLang] = useState(i18n.language);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles((theme) => ({
    logo: {
      marginTop: 40,
      [theme.breakpoints.up("sm")]: {
        width: "403px",
        height: "89px",
      },
    },
    logoSM: {
      marginTop: 40,
      [theme.breakpoints.down("sm")]: {
        width: "60px",
        height: "auto",
      },
    },
    link: {
      textDecoration: "none",
      margin: "0 10px",
      [theme.breakpoints.down("sm")]: {
        margin: "0",
      },
    },
    langsContainer: {
      position: "absolute",
      zIndex: 20,
      transition: "visibility 0s, opacity 0.5s linear",
      visibility: show ? "visible" : "hidden",
      opacity: show ? "1" : "0",
    },
    arrowIcon: {
      cursor: "pointer",
    },
    lang: {
      opacity: 0.51,
      cursor: "pointer",
      fontWeight: "bold",
    },
    mainLang: {
      cursor: "pointer",
      fontWeight: "bold",
    },
    warningBox: {
      marginRight: 15,
      display: "flex",
      // [theme.breakpoints.down("sm")]: {
        // marginRight: 0,
      // },
    },
  }));
  const classes = useStyles();
  const handlShowLangs = useCallback(() => {
    setShow(!show);
  }, [show]);
  const handleChangeLang = useCallback(
    (index) => {
      i18n.changeLanguage(langs[index]);
      localStorage.setItem("lang", langs[index]);
      let newLangs = [...langs];
      newLangs[index] = lang;
      setLang(langs[index]);
      setLangs(newLangs);
      handlShowLangs();
    },
    [langs, lang, handlShowLangs, i18n]
  );
  return (
    <header>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link to={MAIN_ROUTE} title={t('main.title')}>
            {matches ? (
              <img
                src={CoronaLogo}
                title="Coronavirus logo"
                alt="Coronavirus logo"
                className={classes.logoSM}
              />
            ) : (
              <CoroLogoSvg className={classes.logo} />
            )}
          </Link>
          <Box mt="40px" display="flex" ml="10px" alignItems="center">
            <Box className={classes.warningBox}>
              <Link
                to={VACCINATION_ROUTE}
                className={classes.link}
                title={t('vaccination.service_title')}
              >
                <ButtonComponent color="primary" size="small">
                  {t("buttons.info_vaccinations")}
                </ButtonComponent>
              </Link>
              {/* <Link
                to={INFO_ROUTE}
                className={classes.link}
                title={t('info.service_title')}
              >
                {matches ? (
                  <IconButton
                    aria-label={t("buttons.info_btn")}
                    title={t("buttons.info_btn")}
                    color="secondary"
                  >
                    {" "}
                    <ErrorOutlineIcon />
                  </IconButton>
                ) : (
                  <ButtonComponent
                    color="secondary"
                    customIcon={iconYellow}
                    size="small"
                  >
                    {t("buttons.info_btn")}
                  </ButtonComponent>
                )}
              </Link> */}
            </Box>
            <Box>
              <Box display="flex" alignItems="center" onClick={handlShowLangs}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.mainLang}
                >
                  {lang.toUpperCase()}
                </Typography>
                {show ? (
                  <ExpandLessIcon className={classes.arrowIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.arrowIcon} />
                )}
              </Box>
              <Box className={classes.langsContainer}>
                {langs.map((l, index) => (
                  <Typography
                    key={index}
                    className={classes.lang}
                    color="primary"
                    variant="body1"
                    onClick={() => handleChangeLang(index)}
                  >
                    {l.toUpperCase()}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </header>
  );
};

export default HeaderComponent;
