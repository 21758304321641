import React from "react";
import DescriptionComponent from "../../components/DescriptionComponent";
import { useTranslation } from "react-i18next";
import HtmlComponent from "../../components/HtmlComponent";

const AdministartionPage = (props) => {
  const { t } = useTranslation();
  return (
    <DescriptionComponent title="administration">
      <HtmlComponent text={t("administration.content")} />
    </DescriptionComponent>
  );
};

export default AdministartionPage;
