import React from "react";

function PainterSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="45.566"
            height="73.087"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="-0.18"
                    x2="1.278"
                    y1="0.973"
                    y2="-0.013"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="red"></stop>
                    <stop offset="1" stopColor="#d4145a"></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="-0.189"
                    x2="1.628"
                    y1="13.393"
                    y2="-20.603"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="c"
                    x1="-1.591"
                    x2="2.323"
                    y1="2.767"
                    y2="-1.477"
                    xlinkHref="#a"
                ></linearGradient>
            </defs>
            <path
                fill="url(#a)"
                d="M994.4 428.793h-12.66v-3.513a1.059 1.059 0 00-1.06-1.06h-6.942v-2.37h-2.12v2.37h-6.944a1.06 1.06 0 00-1.06 1.06v3.513h-12.66a1.06 1.06 0 00-1.06 1.06v45.732a1.06 1.06 0 001.06 1.06h5.659l-2.138 17.1a1.059 1.059 0 001.052 1.191h4.573a1.06 1.06 0 001.051-.928l1.6-12.791H982.6l1.6 12.791a1.06 1.06 0 001.052.928h4.573a1.059 1.059 0 001.052-1.19l-2.138-17.1h5.661a1.06 1.06 0 001.06-1.06v-45.733a1.06 1.06 0 00-1.06-1.06zm-28.666-2.453h13.886v2.453h-13.886zm-6.569 66.477h-2.437l2.021-16.173h2.437zm3.851-13.72l.306-2.453h18.709l.307 2.453zm25.609 13.72h-2.437l-2.021-16.173h2.433zm4.715-18.293h-41.325v-43.611h41.326z"
                transform="translate(-949.895 -421.85)"
            ></path>
            <path
                fill="url(#b)"
                d="M0 0h36.586v2.12H0z"
                transform="translate(4.49 48.101)"
            ></path>
            <path
                fill="url(#c)"
                d="M982.157 444.966h-14.863a1.06 1.06 0 00-1.06 1.06v14.863a1.06 1.06 0 001.06 1.06h14.863a1.06 1.06 0 001.06-1.06v-14.863a1.06 1.06 0 00-1.06-1.06zm-1.06 14.863h-12.743v-12.743H981.1z"
                transform="translate(-952.38 -425.365)"
            ></path>
        </svg>
    );
}

export default PainterSvg;
