import React from 'react'
import ContainerComponent from './ContainerComponent';
import { Container, Box, Typography, makeStyles } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MAIN_ROUTE } from '../routes';

const useStyles = makeStyles((theme) => ({
    title: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },
    link: {

        textDecoration: 'none',
    },
    btnBox: {
        marginTop: theme.spacing(2),
    }
}));

const DescriptionComponent = ({ custom, title, children, hiddenBtn }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <ContainerComponent>
            <Container>
                <Box mt={'40px'} mb={'80px'}>
                    <Typography variant='h1' color='primary' data-aos='fade-up' className={classes.title} >
                        {custom ? title : t(`${title}.service_title`)}
                    </Typography>
                    <Box data-aos='fade-up' data-aos-delay="300">
                        {children}
                        {!hiddenBtn ?
                            <Box className={classes.btnBox}>
                                <Link to={MAIN_ROUTE} title={t('main.title')} className={classes.link}>
                                    <ButtonComponent  >
                                        {t('buttons.back')}
                                    </ButtonComponent>
                                </Link>
                            </Box>
                            : null}
                    </Box>
                </Box>
            </Container>
        </ContainerComponent>
    )
}


export default DescriptionComponent;