import React from 'react'
import { Box, Typography, Container } from '@material-ui/core';
import SzczecinSvg from '../svg/SzczecinSvg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    typ: {
        fontSize: '14px',
        color: 'white',
        margin: '10px 0'
    },
    footer: {
        background: theme.palette.primary.main,
        padding: '40px 0',
    },
    content: {
        maxWidth: '750px'
    }
}))

const FooterComponent = (props) => {
    const date = new Date();
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Container>
                <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <Box>
                        <a href="https://www.szczecin.eu/"  title='Szczecin EU' target="_blank" className="logo-szczecin" rel="noopener noreferrer">
                            <SzczecinSvg />
                        </a>
                    </Box>
                    <Typography variant='body1' className={classes.typ}>
                        Copyright Ⓒ {date.getFullYear()} Szczecin
                </Typography>
                </Box>
            </Container>
        </footer>
    )
}


export default FooterComponent;