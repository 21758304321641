import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../../components/ButtonComponent';
import LinkComponent from '../../../components/LinkComponent';
import TitleComponent from '../../../components/TitleComponent';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none'
    }
}))

const HelpBlockComponent = ({ to, title, desc, url }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box>
            <TitleComponent title={title} />
            <Typography dangerouslySetInnerHTML={{ __html: desc }} />
            {url ? <LinkComponent
                tel
                url={url}
            />
                : null}
            <Box marginTop='20px' marginBottom='40px'>
                <Link to={to} title={title} className={classes.link}>
                    <ButtonComponent >
                        {t('buttons.see')}
                    </ButtonComponent>
                </Link>
            </Box>
        </Box>
    )
}


export default HelpBlockComponent;