import React from 'react';
import Root from './Root';
import './App.css'
function App() {
  return (
    <Root />
  );
}

export default App;
