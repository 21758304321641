import { Box } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import DescriptionComponent from '../../components/DescriptionComponent';
import { CALL_ROUTE, NEIGHBOUR_ROUTE, VOLUNTARY_ROUTE } from '../../routes';
import HelpBlockComponent from './components/HelpBlockComponent';

const HelpPage = (props) => {
    const { t } = useTranslation();
    return (
        <DescriptionComponent title='help'>
            <Box marginBottom='80px'>
                <HelpBlockComponent title={t('help.neighbour.title')} desc={t('help.neighbour.desc')} to={NEIGHBOUR_ROUTE} />
                <HelpBlockComponent title={t('help.voluntary.title')} desc={t('help.voluntary.desc')} to={VOLUNTARY_ROUTE} />
                <HelpBlockComponent title={t('help.call.title')} desc={t('help.call.desc')} to={CALL_ROUTE} />
            </Box>
        </DescriptionComponent>
    )
}


export default HelpPage;