/* eslint-disable react-hooks/rules-of-hooks */
import { Box, makeStyles } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import DescriptionComponent from '../../components/DescriptionComponent';
import HtmlComponent from '../../components/HtmlComponent';

const useStyles = makeStyles((theme) => ({
    intro: {
        marginBottom: 40,
    }
}))
const slsPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <DescriptionComponent title='sls'>
            <Box className={classes.intro}>
                <HtmlComponent text={t('sls.introduction')} />
            </Box>
        </DescriptionComponent>

    )
}


export default slsPage;