import * as React from "react"

const EmptySvg = (props) => (
	<svg
		viewBox="0 0 757 257"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
		}}
		{...props}
	>
		<path
			d="M1289.16 595.553h-38.73v48.406h-51.5v-48.406h-122.37v-15.1l143.67-190.14h30.2v159.93h38.73v45.31Zm-90.23-45.31V483.25l-49.96 66.993h49.96Z"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#db114c",
				strokeWidth: "8.87px",
			}}
			transform="matrix(.90222 0 0 .90222 -417.289 -338.594)"
		/>
		<path
			d="M1289.16 595.553h-38.73v48.406h-51.5v-48.406h-122.37v-15.1l143.67-190.14h30.2v159.93h38.73v45.31Zm-90.23-45.31V483.25l-49.96 66.993h49.96Z"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#f60412",
				strokeWidth: "8.87px",
			}}
			transform="matrix(.90222 0 0 .90222 -938.595 -338.245)"
		/>
		<path
			d="M427.5 415.9a5.498 5.498 0 0 0-5.591-1.331l-4.97-4.925a29.874 29.874 0 0 0 5.5-14.2h16.24a7.138 7.138 0 0 0 1.553 2.44 6.966 6.966 0 0 0 4.925 2.04c3.821 0 6.965-3.144 6.965-6.965 0-3.821-3.144-6.965-6.965-6.965a6.966 6.966 0 0 0-4.925 2.04 6.541 6.541 0 0 0-1.553 2.44h-16.058c-.697-14.393-11.676-26.348-25.958-28.265v-2.174a5.035 5.035 0 0 0 1.376-1.021 5.462 5.462 0 0 0 1.599-3.86c0-2.994-2.465-5.459-5.459-5.459-2.995 0-5.459 2.465-5.459 5.459 0 1.447.575 2.837 1.598 3.86a6.17 6.17 0 0 0 1.376 1.021v1.908a30.157 30.157 0 0 0-20.411 8.786 28.561 28.561 0 0 0-3.772 4.615l-9.584-5.857a5.238 5.238 0 0 0-1.6-4.26 5.461 5.461 0 0 0-3.845-1.584c-2.995 0-5.459 2.464-5.459 5.459 0 2.995 2.464 5.459 5.459 5.459a5.456 5.456 0 0 0 2.869-.815l9.762 5.946c-4.718 10.555-2.973 22.932 4.481 31.77l-5.768 5.768a6.965 6.965 0 0 0-2.85-.61c-3.821 0-6.965 3.144-6.965 6.965 0 3.821 3.144 6.965 6.965 6.965 3.821 0 6.965-3.144 6.965-6.965 0-.982-.208-1.954-.61-2.85l5.768-5.768a29.951 29.951 0 0 0 21.121 7.055l1.242 7.188a3.027 3.027 0 0 0-.577.488 5.421 5.421 0 0 0-1.559 3.803c0 2.973 2.447 5.419 5.419 5.419 2.973 0 5.419-2.446 5.419-5.419a5.431 5.431 0 0 0-3.821-5.178l-1.242-7.011a29.921 29.921 0 0 0 14.554-7.942l4.748 4.7a5.616 5.616 0 0 0 1.331 5.547 5.477 5.477 0 0 0 3.813 1.546c3.003 0 5.475-2.472 5.475-5.475a5.479 5.479 0 0 0-1.522-3.788Zm-52.713-6.123a25.142 25.142 0 0 1-7.361-17.771c0-13.787 11.345-25.132 25.132-25.132s25.132 11.345 25.132 25.132c0 6.663-2.65 13.06-7.361 17.771-9.751 9.741-25.788 9.743-35.541.004l-.001-.004Z"
			style={{
				fill: "url(#a)",
				fillRule: "nonzero",
			}}
			transform="translate(-704.406 -959.833) scale(2.75878)"
		/>
		<defs>
			<linearGradient
				id="a"
				x1={0}
				y1={0}
				x2={1}
				y2={0}
				gradientUnits="userSpaceOnUse"
				gradientTransform="rotate(-27.455 1024.119 -506.816) scale(108.3718)"
			>
				<stop
					offset={0}
					style={{
						stopColor: "red",
						stopOpacity: 1,
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: "#d4145a",
						stopOpacity: 1,
					}}
				/>
			</linearGradient>
		</defs>
	</svg>
)

export default EmptySvg
