import React from 'react'
import { Box } from '@material-ui/core';
import TitleComponent from './TitleComponent';
import TextWithAComponent from './TextWithAComponent';


const TextComponent = ({ title, children, component = 'p' }) => {
    return (
        <Box mt='40px' mb='40px'>
            {title
                ? <TitleComponent title={title} />
                : ''}
            <TextWithAComponent 
                text={children}
                component={component}
            />
        </Box>
    )
}


export default TextComponent;