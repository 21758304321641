import React, { useCallback, useState, useEffect } from 'react'
import { Button, makeStyles, Box } from '@material-ui/core';
import BlueIcon from '../images/iconBlue.png';

const useStyles = makeStyles(() => ({
    box: {
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: 0,
        width: '25px',
        height: '25px',
        opacity: 0,
        left: 0,
    },
    animatedIcon: {
        animation: `$myEffect 1000ms linear`
    },
    button: {
        borderRadius: '50px',
        padding: '13px 35px 10px',
        lineHeight: '25px',
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 1,
            top: 0,
            width: '25px',
            height: '25px',
        },
        "50%": {
            opacity: 1,
            width: '35px',
            height: '35px',
            top: '-50px',
        },
        "100%": {
            width: '50px',
            height: '50px',
            opacity: 0,
            top: '-100px',
        }
    },
}))
const ButtonComponent = (props) => {
    const { children, customIcon } = props;
    const newProps = { ...props };
    delete newProps.customIcon;
    const [showAnimation, setShowAnimation] = useState(false);
    const classes = useStyles();
    const handleAddAnimation = useCallback(() => {
        setShowAnimation(true)
    }, []);
    useEffect(() => {
        if (showAnimation) {
            const timeout = setTimeout(() => {
                setShowAnimation(false);
            }, 1000)
            return () => clearInterval(timeout)
        }
    }, [showAnimation])
    return (
        <Box className={classes.box}>
            <img
                src={customIcon ? customIcon : BlueIcon}
                title='icon logo animation'
                alt='icon logo animation'
                className={`${classes.icon} ${showAnimation ? classes.animatedIcon : ''}`}
            />
            <Button className={classes.button} color='primary' onMouseEnter={handleAddAnimation} variant='contained' {...newProps}>
                {children}
            </Button>
        </Box>
    )
}


export default ButtonComponent;