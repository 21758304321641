import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    typ: {
        fontSize: '16px',
    },
    container: {
        margin: '10px',
        width: '168px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            margin: '40px 10px'
        },
        [theme.breakpoints.up('md')]: {
            margin: '10px',
        },
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        paddingBottom: '.25em',
        '&:hover': {
            backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23453886' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E")`,
            backgroundPosition: '0 100%',
            backgroundSize: 'auto 5px',
            backgroundRepeat: 'repeat-x',
            textDecoration: 'none',
        }
    }
}))


const PhoneNumbersComponent = ({ title, phones }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography variant='body1' component='h4'  color='primary' className={classes.typ}>
                {t(`phones.${title}`)}
            </Typography>
            <Box mt='10px'>
                {phones.length
                    ? phones.map((phone, i) =>
                        <Typography key={i} variant='body1' className={classes.typ}>
                            <a className={classes.link} href={`tel:+48${phone}`} title={t(`tel:+48${phone}`)}>{phone}</a>
                        </Typography>
                    )
                    : ''
                }
            </Box>
        </Box >
    )
}


export default PhoneNumbersComponent;