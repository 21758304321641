import * as React from "react"

function CoroLogoSvg(props) {
    return (
        <svg width={402.271} height={89.32} viewBox="0 0 402.271 89.32" {...props}>
            <defs>
                <linearGradient
                    id="prefix__aCoroLogoSvg"
                    x1={0.019}
                    y1={0.733}
                    x2={0.934}
                    y2={0.173}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="red" />
                    <stop offset={1} stopColor="#d4145a" />
                </linearGradient>
                <linearGradient
                    id="prefix__bCoroLogoSvg"
                    x1={0.138}
                    y1={0.533}
                    x2={6.592}
                    y2={-0.048}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__cCoroLogoSvg"
                    x1={-0.861}
                    y1={0.66}
                    x2={6.12}
                    y2={-0.162}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__dCoroLogoSvg"
                    x1={-3.026}
                    y1={0.775}
                    x2={7.252}
                    y2={-0.063}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__eCoroLogoSvg"
                    x1={-2.78}
                    y1={0.886}
                    x2={4.201}
                    y2={0.064}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__fCoroLogoSvg"
                    x1={-4.371}
                    y1={1.043}
                    x2={3.314}
                    y2={0.206}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__gCoroLogoSvg"
                    x1={-4.978}
                    y1={1.173}
                    x2={1.893}
                    y2={0.349}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__hCoroLogoSvg"
                    x1={-4.161}
                    y1={1.334}
                    x2={0.524}
                    y2={0.475}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__iCoroLogoSvg"
                    x1={-23.621}
                    y1={1.153}
                    x2={-2.51}
                    y2={0.586}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__jCoroLogoSvg"
                    x1={-12.275}
                    y1={1.528}
                    x2={-1.997}
                    y2={0.691}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__kCoroLogoSvg"
                    x1={-10.053}
                    y1={1.641}
                    x2={-2.368}
                    y2={0.802}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
                <linearGradient
                    id="prefix__lCoroLogoSvg"
                    x1={-13.171}
                    y1={1.75}
                    x2={-4.185}
                    y2={0.928}
                    xlinkHref="#prefix__aCoroLogoSvg"
                />
            </defs>
            <path
                d="M427.5 415.9a5.491 5.491 0 00-5.591-1.331l-4.97-4.925a29.872 29.872 0 005.5-14.2h16.24a7.134 7.134 0 001.553 2.44 6.965 6.965 0 100-9.85 6.531 6.531 0 00-1.553 2.44h-16.058a29.981 29.981 0 00-25.958-28.265v-2.174a5.04 5.04 0 001.376-1.021 5.459 5.459 0 10-7.721 0 6.154 6.154 0 001.376 1.021v1.908a30.151 30.151 0 00-20.411 8.786 28.574 28.574 0 00-3.772 4.615l-9.584-5.857a5.234 5.234 0 00-1.6-4.26 5.459 5.459 0 10-.976 8.519l9.762 5.946a30.177 30.177 0 004.481 31.77l-5.768 5.768a6.965 6.965 0 103.505 3.505l5.768-5.768a29.943 29.943 0 0021.121 7.055l1.242 7.188a3.065 3.065 0 00-.577.488 5.419 5.419 0 105.458-1.375l-1.242-7.011a29.915 29.915 0 0014.554-7.942l4.748 4.7a5.609 5.609 0 001.331 5.547 5.475 5.475 0 007.766-7.717zm-52.713-6.123a25.132 25.132 0 1135.542 0 25.147 25.147 0 01-35.541.004z"
                transform="translate(-347.011 -349.702)"
                fill="url(#prefix__aCoroLogoSvg)"
            />
            <path
                d="M920.056 551.222l-10.081-11.346v11.346h-6.517v-32.3h6.517v11.018l8.017-11.018h8.108L913.867 535l15.05 16.223z"
                transform="translate(-767.548 -491.588)"
                fill="url(#prefix__bCoroLogoSvg)"
            />
            <path
                d="M1004.433 570.862c0-6.939 5.532-11.44 11.768-11.44s11.768 4.5 11.768 11.44c0 6.892-5.532 11.393-11.768 11.393s-11.768-4.501-11.768-11.393zm17.3 0a5.534 5.534 0 10-5.533 5.673 5.473 5.473 0 005.534-5.673z"
                transform="translate(-843.86 -522.199)"
                fill="url(#prefix__cCoroLogoSvg)"
            />
            <path
                d="M1136.558 560.125l-1.406 5.861a6.374 6.374 0 00-3.141-.8c-3.141 0-5.3 2.907-5.3 9.143v7.5h-6.142v-21.845h6.1v4.642c1.219-3.657 3.7-5.2 6.423-5.2a7.8 7.8 0 013.466.699z"
                transform="translate(-931.631 -522.199)"
                fill="url(#prefix__dCoroLogoSvg)"
            />
            <path
                d="M1191.789 570.862c0-6.939 5.532-11.44 11.768-11.44s11.768 4.5 11.768 11.44c0 6.892-5.532 11.393-11.768 11.393s-11.768-4.501-11.768-11.393zm17.3 0a5.534 5.534 0 10-5.533 5.673 5.473 5.473 0 005.534-5.673z"
                transform="translate(-985.455 -522.199)"
                fill="url(#prefix__eCoroLogoSvg)"
            />
            <path
                d="M1329.306 569.831v12h-6.142v-11.344c0-3.469-2.063-5.017-4.173-5.017-2.2 0-4.923 1.172-4.923 5.158v11.206h-6.142v-21.85h6.142v3.657c.938-2.954 4.407-4.22 6.564-4.22 5.72.001 8.721 3.845 8.674 10.41z"
                transform="translate(-1073.226 -522.199)"
                fill="url(#prefix__fCoroLogoSvg)"
            />
            <path
                d="M1438.185 560.129v21.849h-6.142v-2.719c-1.547 2.157-4.595 3.141-7.033 3.141-5.626 0-10.737-4.36-10.737-11.393s5.111-11.393 10.737-11.393c2.532 0 5.486.938 7.033 3.141v-2.626zm-6.33 10.878a5.673 5.673 0 10-5.673 5.673 5.648 5.648 0 005.673-5.674z"
                transform="translate(-1153.598 -522.343)"
                fill="url(#prefix__gCoroLogoSvg)"
            />
            <path
                d="M1560.877 561.725l-7.736 21.849h-5.251l-4.5-14.206-4.36 14.206h-5.251l-7.97-21.849h6.658l4.126 13.316 4.173-13.316h5.157l4.173 13.316 4.126-13.316z"
                transform="translate(-1237.89 -523.939)"
                fill="url(#prefix__hCoroLogoSvg)"
            />
            <path
                d="M1679.952 519.311a3.9 3.9 0 113.892 3.7 3.815 3.815 0 01-3.892-3.7zm.844 7.6h6.142v21.849h-6.138z"
                transform="translate(-1354.386 -489.121)"
                fill="url(#prefix__iCoroLogoSvg)"
            />
            <path
                d="M1749.881 560.125l-1.406 5.861a6.373 6.373 0 00-3.141-.8c-3.141 0-5.3 2.907-5.3 9.143v7.5h-6.142v-21.845h6.1v4.642c1.219-3.657 3.7-5.2 6.423-5.2a7.8 7.8 0 013.466.699z"
                transform="translate(-1395.153 -522.199)"
                fill="url(#prefix__jCoroLogoSvg)"
            />
            <path
                d="M1833.207 561.725v21.849h-6.142v-3.564c-.938 2.86-4.407 4.079-6.564 4.079-5.72 0-8.721-3.8-8.674-10.362v-12h6.142v11.346c0 3.423 2.063 4.97 4.173 4.97 2.2 0 4.923-1.172 4.923-5.111v-11.207z"
                transform="translate(-1454.051 -523.939)"
                fill="url(#prefix__kCoroLogoSvg)"
            />
            <path
                d="M1919.135 574.8h5.767c0 1.266 1.078 2.626 3.235 2.626 1.781 0 3.141-.75 3.141-2.016 0-.938-.75-1.406-2.391-1.828l-2.907-.7c-5.626-1.5-6.376-4.642-6.376-6.939 0-3.376 3.516-6.517 8.58-6.517 3.985 0 8.674 1.969 8.627 7.127h-5.861c0-1.453-1.219-2.25-2.672-2.25-1.407 0-2.532.7-2.532 1.828 0 1.031.938 1.547 2.157 1.876l3.61.938c5.251 1.406 5.907 4.688 5.907 6.47 0 4.6-4.688 6.846-9.189 6.846-4.595-.006-9.049-2.631-9.096-7.461z"
                transform="translate(-1535.15 -522.199)"
                fill="url(#prefix__lCoroLogoSvg)"
            />
        </svg>
    )
}

export default CoroLogoSvg
