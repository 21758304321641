import React from 'react'
import ServiceComponent from './ServiceComponent';
import ClockSvg from '../../../svg/ClockSvg';
import HandsSvg from '../../../svg/HandsSvg';
import ManSvg from '../../../svg/ManSvg';
import PainterSvg from '../../../svg/PainterSvg';
import TabletSvg from '../../../svg/TabletSvg';
// import SchoolSvg from '../../../svg/SchoolSvg'
import { Container, Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import SupportSvg from '../../../svg/SupportSvg';
import PhoneNumbersComponent from './PhoneNumbersComponent';
// SZCZECIN_SCHOOL_ROUTE
import { ENTREPRENEURS_ROUTE, SLS_ROUTE, ARTISTS_ROUTE, ADMINISTRATION_ROUTE, KEEP_UP_ROUTE, HELP_ROUTE } from '../../../routes';
import { useTranslation } from 'react-i18next';
import PeopleSvg from '../../../svg/PeopleSvg';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        background: '#F5F6FBBA',
        padding: '100px 20px',
    },
    paper: {
        marginTop: '80px',
        padding: '40px 10px',
    },
    phoneTitle: {
        fontSize: '34px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            margin: '20px',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 20px 0 50px',
        },

    },
    phoneContainer: {
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
        [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap',
        },
    },
    phones: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    }
}))


const ServicesComponent = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box className={classes.contentContainer} id='services'>
            <Container>
                <Grid container spacing={2}>
                    <ServiceComponent
                        Icon={ClockSvg}
                        title='keep_up'
                        url={KEEP_UP_ROUTE}
                    />
                    <ServiceComponent
                        Icon={HandsSvg}
                        title='szs'
                        url={SLS_ROUTE}
                    />
                    <ServiceComponent
                        Icon={ManSvg}
                        title='enterpreneurs'
                        content={[t('enterpreneurs.service_content_1'), t('enterpreneurs.service_content_2'), t('enterpreneurs.service_content_3'), t('enterpreneurs.service_content_4'),]}
                        url={ENTREPRENEURS_ROUTE}
                    />
                    <ServiceComponent
                        Icon={PainterSvg}
                        title='artists'
                        content={[t('artists.service_content_1'), t('artists.service_content_2')]}
                        url={ARTISTS_ROUTE}
                    />
                    <ServiceComponent
                        Icon={TabletSvg}
                        title='administration'
                        url={ADMINISTRATION_ROUTE}
                    />
                    {/* <ServiceComponent
                        Icon={SchoolSvg}
                        title='szczecin_schools'
                        url={SZCZECIN_SCHOOL_ROUTE}
                    /> */}
                    <ServiceComponent
                        Icon={PeopleSvg}
                        title='help'
                        url={HELP_ROUTE}
                    />
                </Grid>
                <Paper className={classes.paper} data-aos='fade-up'>
                    <Box display='flex' alignItems='flex-start' justifyContent='center' className={classes.phoneContainer}>
                        <Box>
                            <SupportSvg />
                        </Box>
                        <Typography variant='h2' component='h3' color='primary' className={classes.phoneTitle}>
                            {t('phones.title')}
                        </Typography>
                        <Box display='flex' flexWrap='wrap' className={classes.phones}>
                            <PhoneNumbersComponent
                                title='city_hall'
                                phones={['91 424 5000']}
                            />
                            <PhoneNumbersComponent
                                title='rydla_branch'
                                phones={['91 462 7172', '91 462 8473']}
                            />
                            <PhoneNumbersComponent
                                title='car_registry'
                                phones={['91 424 53 37', '91 435 11 32']}
                            />
                            <PhoneNumbersComponent
                                title='business'
                                phones={['91 424 5354']}
                            />
                            <PhoneNumbersComponent
                                title='registry'
                                phones={['91 424 5277']}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    )
}


export default ServicesComponent;