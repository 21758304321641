import React from 'react'
import DescriptionComponent from '../../components/DescriptionComponent';
import TextComponent from '../../components/TextComponent';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextWithAComponent from '../../components/TextWithAComponent';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ReactPlayer from 'react-player';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 40
    },
    chevronDown: {
        width: '20px',
        height: '20px',
        marginRight: '20px',
        background: "#c0cbed5c",
        borderRadius: '50%',
        color: theme.palette.primary.main,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,

    },
}))


const SzczecinSchoolPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <DescriptionComponent title='szczecin_schools'>
            <div className={classes.root}>
                <TextComponent>
                    {t('szczecin_schools.service_intro')}
                </TextComponent>
                <Box mb='40px'>
                    <ReactPlayer
                        url='videos/koronawirus_anim_YouTube_1080.mp4'
                        width='100%'
                        height='100%'
                        controls={true}
                    />
                </Box>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <KeyboardArrowDownIcon className={classes.chevronDown} />
                        <Typography className={classes.heading}> {t('szczecin_schools.accordion1_title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextWithAComponent text={t('szczecin_schools.accordion1_desc')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <KeyboardArrowDownIcon className={classes.chevronDown} />
                        <Typography className={classes.heading}> {t('szczecin_schools.accordion2_title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextWithAComponent text={t('szczecin_schools.accordion2_desc')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <KeyboardArrowDownIcon className={classes.chevronDown} />
                        <Typography className={classes.heading}> {t('szczecin_schools.accordion3_title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextWithAComponent text={t('szczecin_schools.accordion3_desc')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel4a-content"
                        id="panel4a-header">
                        <KeyboardArrowDownIcon className={classes.chevronDown} />
                        <Typography className={classes.heading}> {t('szczecin_schools.accordion4_title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextWithAComponent text={t('szczecin_schools.accordion4_desc')} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel5a-content"
                        id="panel5a-header">
                        <KeyboardArrowDownIcon className={classes.chevronDown} />
                        <Typography className={classes.heading}> {t('szczecin_schools.accordion5_title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextWithAComponent text={t('szczecin_schools.accordion5_desc')} />
                    </AccordionDetails>
                </Accordion>

            </div>
        </DescriptionComponent>
    )
}


export default SzczecinSchoolPage;