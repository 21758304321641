import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = () => {
    const theme = createMuiTheme({
        '*': {
            fontFamily: '"sofia-pro", "Roboto", "Helvetica", "Arial", "sans-serif"',
        },
        typography: {
            fontFamily: '"sofia-pro", "Roboto", "Helvetica", "Arial", "sans-serif"',
            h1: {
                fontSize: '40px',
                fontWeight: 'bold',
                margin: '20px 0',
            },
            body1: {
                fontSize: '16px',
            },
            h6: {
                fontSize: '22px',
                fontWeight: 'bold',
            },
        },
        palette: {
            primary: {
                main: '#2A3581',
                dark: '#051441',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },
    })
    return {
        ...theme,
    }
}

const mainTheme = createTheme();
export default mainTheme