import { makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
   title: {
        fontSize: '22px',
        marginBottom: '20px',
        fontWeight: 'bold'
   } 
}))

const TitleComponent = ({title}) => {
    const classes = useStyles();
    return (
        <Typography variant='h2' color='primary' className={classes.title}>
            {title}
        </Typography>
    )
}


export default TitleComponent;