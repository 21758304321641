import React from 'react'
import DescriptionComponent from '../../components/DescriptionComponent';
import TextComponent from '../../components/TextComponent';
import { useTranslation } from 'react-i18next';

const KeepUpPage = (props) => {
   const { t } = useTranslation();
    return (
        <DescriptionComponent title='keep_up'>
            <TextComponent title={t('keep_up.szczecin_news_title')}>
                {t('keep_up.szczecin_news_content')}
            </TextComponent>
        </DescriptionComponent>
    )
}


export default KeepUpPage;