import React, { useCallback } from 'react'
import { Container, Box, Typography, Grid, makeStyles } from '@material-ui/core';
import ButtonComponent from '../../components/ButtonComponent';
import BgImg from '../../images/bg/bg_001.png'
import BgImgBack from '../../images/bg/bg_002.png'
import ServicesComponent from './components/ServicesComponent';
import ContainerComponent from '../../components/ContainerComponent';
import { Parallax } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '0px',
        },
        marginBottom: '20px',
    },
    img: {
        width: '100%'
    },
    imgFront: {
        position: 'absolute',
        zIndex: '1',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        margin: 'auto',
        width: '500px',
        height: '500px',
    },
    imgFrontSize: {
        width: '500px',
        height: '500px',
    },
    contentContainer: {
        background: '#F5F6FBBA',
        padding: '100px 20px',
    },
    subtitle: {
        fontWeight: 'normal',
        lineHeight: '30px',
    },
    logo: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    h1: {
        marginTop: 0,
    },
    titleContent: {
        [theme.breakpoints.down('md')]: {
            marginTop: '0',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '-100px',
        },
    },
}))

const MainPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleGoContent = useCallback(() => {
        document.location.href = `#services`
    }, [])
    return (
        <>
            <ContainerComponent>
                <Container>
                    <Grid container alignItems='center' className={classes.titleContainer}>
                        <Grid item md={5} xs={12} className={classes.titleContent}>
                            <Typography variant='h1' color='primary' className={classes.h1}>
                                {t('main.title')}
                            </Typography>
                            <Typography variant='h6' component='p' className={classes.subtitle}>
                                {t('main.subtitle')}
                            </Typography>
                            <Box mt='50px' display='flex'>
                            <Box  >
                                <ButtonComponent onClick={handleGoContent} >
                                    {t('buttons.go')}
                                </ButtonComponent>
                            </Box>

                            </Box>
                        </Grid>
                        <Grid item sm={7} className={classes.logo}>
                            <img src={BgImgBack} title='header logo background' alt='header logo background' className={classes.img} />
                            <Parallax className={classes.imgFront} y={[-20, 20]} tagOuter="figure">
                                <img src={BgImg} title='header logo front' alt='header logo front' className={classes.imgFrontSize} />
                            </Parallax>
                        </Grid>
                    </Grid>
                </Container>
                <ServicesComponent />
            </ContainerComponent>
        </>
    )
}


export default MainPage;