import React, { useEffect } from 'react'
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import { Box } from '@material-ui/core';
import CookiesComponent from './CookiesComponent';

const ContainerComponent = ({children}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [window.location.href])
    return (
        <>
            <HeaderComponent />
            <Box minHeight='calc(100vh - 136px)'>
            {children}
            </Box>
            <CookiesComponent />
            <FooterComponent />
        </>
    )
}


export default ContainerComponent;